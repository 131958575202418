import React from 'react';
import Project from './project';

const Vectorhang = ({transitionStatus}) => {
    return (
        <>
            <Project 
                transitionStatus={transitionStatus}
                title="Vectorhang"
                subtitle="music video"
                date="2020-2021"
                content={
                    <>
                        <div style={{height:0, position: 'relative', paddingBottom: '56.25%', marginBottom: '40px'}}>
                            <iframe
                                title="Vectorhang"
                                style={{width: '100%', height: '100%', top: 0, left: 0, position: 'absolute'}}
                                src={"https://www.youtube.com/embed/NxzFDs9ahwA?modestbranding=1?autohide=1&showinfo=0"}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                frameBorder="0"
                                webkitallowfullscreen="true"
                                mozallowfullscreen="true"
                                allowFullScreen
                            /> 
                        </div>
                        <p>Electric guitar, synth and sliced drum breaks. Audio-reactive laser visuals.</p>
                        <p>Laser controlled with 5 channels of audio as control voltage signals, 
                            2 for the X/Y positions of the laser beam and 3 for switching R/G/B diodes on and off.
                        <br/>Control software built in Cycling ‘74 Max / Gen~ / Jitter and Javascript.</p>
                        <p>Released on March 19, 2021.
                        <br/>Mastering by Chris Walter and Tim van Veen at The Lullabye Factory.
                            Special thanks to Rashin Teimouri and Soheil Shayesteh for help with filming.</p>
                        <p>Also available on <a rel="noreferrer" target="_blank" href="https://lukabatista.bandcamp.com/track/vectorhang">Bandcamp
                        </a> and <a rel="noreferrer" target="_blank" href="https://open.spotify.com/album/1td43JMh8nfrr1A8POvS5Z">music streaming platforms</a>.</p>
                    </>
                }
            />
        </>
    );
}

export default Vectorhang;